@import url("//fonts.googleapis.com/css?family=Muli:300,300i,400,400i,600,600i,700,700i,800,800i");
@import "variables";

body.login {
	background: #000 url(../img/DSC_6114.jpg) no-repeat center center / cover;
	font-family: $baseFontFamily;
	h1 {
		a {
			width: 200px;
			height: 210px;
			background: url(../img/logo@2x.png) no-repeat center center / contain;
		}
	}
	form {
		box-shadow: none;
	}
}

#login {
	background: none;
	box-shadow: none;
}

#loginform {
	background: rgba(#FFF, 0.9);
}

.login #backtoblog,
.login #nav {
	margin: 0;
	padding: 0 24px 24px;
	background: rgba(#FFF, 0.9);
	a {
		color: $color1;
		&:hover {
			color: $color1;
			text-decoration: underline;
		}
	}
}

.wp-core-ui {
	.button-primary {
		background: $color1;
		color: #FFF;
		border: none;
		border-radius: 0;
		box-shadow: none;
		text-shadow: none;
		text-transform: uppercase;
		font-weight: 700;
		transition: all 0.2s ease-in-out;
		&:hover,
		&:active,
		&:focus {
			background: $color2 !important;
			outline: 0;
			box-shadow: none !important;
		}
	}
}