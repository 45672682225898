// GENERAL
// ---------------

$color1:              #ff6600;
$color2:              #000;
$color3:              #999;

// TYPOGRAPHY
// ---------------

$textColor:           #333;

$baseFontSize:        16px;
$em-base:             $baseFontSize;
$baseLineHeight:      1.612;
$baseTypeSpacing:     round($baseFontSize * $baseLineHeight);
$baseFontFamily:      "Muli", Helvetica, Arial, sans-serif;
$secondaryFontFamily: $baseFontFamily;

$headingsFontFamily:  $secondaryFontFamily;
$headingsColor:       $color2;

$hrColor:             #DDD;